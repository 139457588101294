// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-bar {
    display: flex;
    justify-content: right;
    align-items: flex-start;
}
.icon-bar img {
    max-height: 3rem;
    margin: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/SocialBar/SocialBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".icon-bar {\n    display: flex;\n    justify-content: right;\n    align-items: flex-start;\n}\n.icon-bar img {\n    max-height: 3rem;\n    margin: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
