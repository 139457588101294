// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Inter/Inter-VariableFont_slnt,wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Inter/static/Inter-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: "Inter";
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Inter-Bold";
	src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
html {
	font-size: 62.5%;
}
body {
	width: 100vw;
	font-family: "Inter", sans-serif;
	color: var(--www-color-float-fg1);
	/* clip-path: 0 0 , 100% 0 , 100% 50%, 0 70%; */
}
::-webkit-scrollbar {
	width: 0.5rem;
	display: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Theme1.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB,+DAA8E;CAC9E,mBAAmB;CACnB,kBAAkB;AACnB;;AAEA;CACC,yBAAyB;CACzB,+DAAmE;AACpE;AACA;CACC,sBAAsB;CACtB,SAAS;CACT,UAAU;AACX;AACA;CACC,gBAAgB;AACjB;AACA;CACC,YAAY;CACZ,gCAAgC;CAChC,iCAAiC;CACjC,+CAA+C;AAChD;AACA;CACC,aAAa;CACb,aAAa;AACd","sourcesContent":["@font-face {\n\tfont-family: \"Inter\";\n\tsrc: url(\"../fonts/Inter/Inter-VariableFont_slnt,wght.ttf\") format(\"truetype\");\n\tfont-weight: normal;\n\tfont-style: normal;\n}\n\n@font-face {\n\tfont-family: \"Inter-Bold\";\n\tsrc: url(\"../fonts/Inter/static/Inter-Bold.ttf\") format(\"truetype\");\n}\n* {\n\tbox-sizing: border-box;\n\tmargin: 0;\n\tpadding: 0;\n}\nhtml {\n\tfont-size: 62.5%;\n}\nbody {\n\twidth: 100vw;\n\tfont-family: \"Inter\", sans-serif;\n\tcolor: var(--www-color-float-fg1);\n\t/* clip-path: 0 0 , 100% 0 , 100% 50%, 0 70%; */\n}\n::-webkit-scrollbar {\n\twidth: 0.5rem;\n\tdisplay: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
