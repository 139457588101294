// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading-div {
	z-index: 10;
	color: white;
	width: 100%;
	/* min-height: 100px; */
	text-align: center;
	background-color: var(--primary-color1);
}
`, "",{"version":3,"sources":["webpack://./src/pages/ApiWelcomePage/Heading/HeadingComponent.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,WAAW;CACX,uBAAuB;CACvB,kBAAkB;CAClB,uCAAuC;AACxC","sourcesContent":[".heading-div {\n\tz-index: 10;\n\tcolor: white;\n\twidth: 100%;\n\t/* min-height: 100px; */\n\ttext-align: center;\n\tbackground-color: var(--primary-color1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
