// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#panel-map .map {
	width: 350px !important;
	height: 350px !important;
}

#panel-map .map #coordinates {
	top: 0;
	background-image: unset;
	background-color: var(--primary-color1);
	align-items: flex-start;
	height: 60px;
}
.welcome-outer-box .left {
}
`, "",{"version":3,"sources":["webpack://./src/pages/ApiWelcomePage/PanelMap/PanelMap.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,wBAAwB;AACzB;;AAEA;CACC,MAAM;CACN,uBAAuB;CACvB,uCAAuC;CACvC,uBAAuB;CACvB,YAAY;AACb;AACA;AACA","sourcesContent":["#panel-map .map {\n\twidth: 350px !important;\n\theight: 350px !important;\n}\n\n#panel-map .map #coordinates {\n\ttop: 0;\n\tbackground-image: unset;\n\tbackground-color: var(--primary-color1);\n\talign-items: flex-start;\n\theight: 60px;\n}\n.welcome-outer-box .left {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
