// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sim-list-item {
	width: 90%;
	min-height: 5rem;
	display: flex;
	border-radius: 5px;
}
.sim-list-item p {
	color: black;
}

.tile-entry {
	display: flex;
	align-items: center;
	width: 100%;
}
.tile-entry p {
	font-size: 1.5rem;
}

.icon-area {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	color: var(--meteo-text);
	align-items: center;
	margin-right: 1rem;
}
.sim-list-item:hover {
	background-color: #b9dff1;
	filter: drop-shadow(0px 0px 3px var(--neutral-color1));
}
.sim-list-item h3 {
	font-size: 1.8rem;
	color: black;
}

.sim-icon {
	width: 2.5rem;
	height: 2.5rem;
	cursor: pointer;
}
.sim-icon path {
	stroke: var(--meteo-text);
}

.sim-icon:hover path {
	stroke: var(--primary-color1);
}

.icon-img:hover {
	filter: drop-shadow(0px 0px 2px var(--neutral-color1));
}
`, "",{"version":3,"sources":["webpack://./src/pages/ApiWelcomePage/SimulationList/SimulationTiles/SimTile/SimTile.css"],"names":[],"mappings":"AAAA;CACC,UAAU;CACV,gBAAgB;CAChB,aAAa;CACb,kBAAkB;AACnB;AACA;CACC,YAAY;AACb;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,WAAW;AACZ;AACA;CACC,iBAAiB;AAClB;;AAEA;CACC,WAAW;CACX,aAAa;CACb,yBAAyB;CACzB,wBAAwB;CACxB,mBAAmB;CACnB,kBAAkB;AACnB;AACA;CACC,yBAAyB;CACzB,sDAAsD;AACvD;AACA;CACC,iBAAiB;CACjB,YAAY;AACb;;AAEA;CACC,aAAa;CACb,cAAc;CACd,eAAe;AAChB;AACA;CACC,yBAAyB;AAC1B;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,sDAAsD;AACvD","sourcesContent":[".sim-list-item {\n\twidth: 90%;\n\tmin-height: 5rem;\n\tdisplay: flex;\n\tborder-radius: 5px;\n}\n.sim-list-item p {\n\tcolor: black;\n}\n\n.tile-entry {\n\tdisplay: flex;\n\talign-items: center;\n\twidth: 100%;\n}\n.tile-entry p {\n\tfont-size: 1.5rem;\n}\n\n.icon-area {\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tcolor: var(--meteo-text);\n\talign-items: center;\n\tmargin-right: 1rem;\n}\n.sim-list-item:hover {\n\tbackground-color: #b9dff1;\n\tfilter: drop-shadow(0px 0px 3px var(--neutral-color1));\n}\n.sim-list-item h3 {\n\tfont-size: 1.8rem;\n\tcolor: black;\n}\n\n.sim-icon {\n\twidth: 2.5rem;\n\theight: 2.5rem;\n\tcursor: pointer;\n}\n.sim-icon path {\n\tstroke: var(--meteo-text);\n}\n\n.sim-icon:hover path {\n\tstroke: var(--primary-color1);\n}\n\n.icon-img:hover {\n\tfilter: drop-shadow(0px 0px 2px var(--neutral-color1));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
