// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.legend-list {
	list-style-type: none;
	font-size: 0.9rem;
}

.horizontal-legend {
	display: flex;
	flex-wrap: wrap;

	/* flex-direction: column; */
	/* width: 100px; */
}
.horizontal-legend p {
	font-size: 0.8rem;
	padding: 0;
	margin: 0;
}
@media (min-width: 500px) {


	.legend-list {
		list-style-type: none;
		font-size: unset;
	}
	.horizontal-legend {
		display: flex;
		flex-direction: row;
	}
	.horizontal-legend span {
		font-size: 1.2rem;
		padding: 0;
		margin: 0;
	}
}
@media (max-width: 280px) {
	.horizontal-legend {
		/* flex-direction: column;  */
		flex-wrap: wrap;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/charts/chartComponents/CustomLegend/CustomLegend.css"],"names":[],"mappings":";AACA;CACC,qBAAqB;CACrB,iBAAiB;AAClB;;AAEA;CACC,aAAa;CACb,eAAe;;CAEf,4BAA4B;CAC5B,kBAAkB;AACnB;AACA;CACC,iBAAiB;CACjB,UAAU;CACV,SAAS;AACV;AACA;;;CAGC;EACC,qBAAqB;EACrB,gBAAgB;CACjB;CACA;EACC,aAAa;EACb,mBAAmB;CACpB;CACA;EACC,iBAAiB;EACjB,UAAU;EACV,SAAS;CACV;AACD;AACA;CACC;EACC,6BAA6B;EAC7B,eAAe;CAChB;AACD","sourcesContent":["\n.legend-list {\n\tlist-style-type: none;\n\tfont-size: 0.9rem;\n}\n\n.horizontal-legend {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\n\t/* flex-direction: column; */\n\t/* width: 100px; */\n}\n.horizontal-legend p {\n\tfont-size: 0.8rem;\n\tpadding: 0;\n\tmargin: 0;\n}\n@media (min-width: 500px) {\n\n\n\t.legend-list {\n\t\tlist-style-type: none;\n\t\tfont-size: unset;\n\t}\n\t.horizontal-legend {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t}\n\t.horizontal-legend span {\n\t\tfont-size: 1.2rem;\n\t\tpadding: 0;\n\t\tmargin: 0;\n\t}\n}\n@media (max-width: 280px) {\n\t.horizontal-legend {\n\t\t/* flex-direction: column;  */\n\t\tflex-wrap: wrap;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
