// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-panel {
	position: absolute;
	left: 0;
	top: 0;
	min-width: 40rem;
	min-height: 40rem;
	background-color: var(--www-color-float-bg2);
	z-index: 11;
	border-radius: 10px;
}

.sim-button {
	cursor: pointer;
	width: 20rem;
	line-height: 2.5rem;
	height: 3rem;
	align-self: center;
	margin: 1rem 1rem;
	color: white;
	font-size: 1.4rem;
	text-align: center;
	box-shadow: 0px 0px 5px var(--neutral-color1);
}
.sim-button.save {
	background-color: var(--primary-color1);
}
.sim-button.parse{
    background-color: var(--risk-item-2);

}
.button-area {
	display: flex;
	justify-content: center;
}
.sim-button.cancel {
	background-color: var(--risk-item-1);
}

.close-icon {
	align-self: flex-end;
	margin: 1rem;
	cursor: pointer;
}
.entry {
	display: flex;
	justify-content: flex-start;
	gap: 1rem;
	margin: 1rem;
}
.entry h3 {
	width: 6rem;
}
.json-pretty {
	margin: 1rem;
	height: 100%;
	padding: 1rem;
	border-radius: 10px;
	background-color: var(--www-color-float-bg1);
	color: var(--meteo-text);
	font-size: 1.2rem;
	overflow-y: scroll;
	z-index: 12;
}

`, "",{"version":3,"sources":["webpack://./src/pages/ApiWelcomePage/SimulationList/SimulationEditPanel/SimulationEditPanel.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,OAAO;CACP,MAAM;CACN,gBAAgB;CAChB,iBAAiB;CACjB,4CAA4C;CAC5C,WAAW;CACX,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,YAAY;CACZ,mBAAmB;CACnB,YAAY;CACZ,kBAAkB;CAClB,iBAAiB;CACjB,YAAY;CACZ,iBAAiB;CACjB,kBAAkB;CAClB,6CAA6C;AAC9C;AACA;CACC,uCAAuC;AACxC;AACA;IACI,oCAAoC;;AAExC;AACA;CACC,aAAa;CACb,uBAAuB;AACxB;AACA;CACC,oCAAoC;AACrC;;AAEA;CACC,oBAAoB;CACpB,YAAY;CACZ,eAAe;AAChB;AACA;CACC,aAAa;CACb,2BAA2B;CAC3B,SAAS;CACT,YAAY;AACb;AACA;CACC,WAAW;AACZ;AACA;CACC,YAAY;CACZ,YAAY;CACZ,aAAa;CACb,mBAAmB;CACnB,4CAA4C;CAC5C,wBAAwB;CACxB,iBAAiB;CACjB,kBAAkB;CAClB,WAAW;AACZ","sourcesContent":[".edit-panel {\n\tposition: absolute;\n\tleft: 0;\n\ttop: 0;\n\tmin-width: 40rem;\n\tmin-height: 40rem;\n\tbackground-color: var(--www-color-float-bg2);\n\tz-index: 11;\n\tborder-radius: 10px;\n}\n\n.sim-button {\n\tcursor: pointer;\n\twidth: 20rem;\n\tline-height: 2.5rem;\n\theight: 3rem;\n\talign-self: center;\n\tmargin: 1rem 1rem;\n\tcolor: white;\n\tfont-size: 1.4rem;\n\ttext-align: center;\n\tbox-shadow: 0px 0px 5px var(--neutral-color1);\n}\n.sim-button.save {\n\tbackground-color: var(--primary-color1);\n}\n.sim-button.parse{\n    background-color: var(--risk-item-2);\n\n}\n.button-area {\n\tdisplay: flex;\n\tjustify-content: center;\n}\n.sim-button.cancel {\n\tbackground-color: var(--risk-item-1);\n}\n\n.close-icon {\n\talign-self: flex-end;\n\tmargin: 1rem;\n\tcursor: pointer;\n}\n.entry {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\tgap: 1rem;\n\tmargin: 1rem;\n}\n.entry h3 {\n\twidth: 6rem;\n}\n.json-pretty {\n\tmargin: 1rem;\n\theight: 100%;\n\tpadding: 1rem;\n\tborder-radius: 10px;\n\tbackground-color: var(--www-color-float-bg1);\n\tcolor: var(--meteo-text);\n\tfont-size: 1.2rem;\n\toverflow-y: scroll;\n\tz-index: 12;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
