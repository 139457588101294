// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-logo-div {
    display: flex;
    flex-wrap: nowrap;
  }
  
  .footer a.logo img {
    max-height: 35px;
    margin: 0.5rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer/LogoBar/LogoBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":[".footer-logo-div {\n    display: flex;\n    flex-wrap: nowrap;\n  }\n  \n  .footer a.logo img {\n    max-height: 35px;\n    margin: 0.5rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
