// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.footer-container-mobile {
	justify-self: end;
	bottom: 0;
	width: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/pages/GenericPage/GenericPage.css"],"names":[],"mappings":";;AAEA;CACC,iBAAiB;CACjB,SAAS;CACT,WAAW;AACZ","sourcesContent":["\n\n.footer-container-mobile {\n\tjustify-self: end;\n\tbottom: 0;\n\twidth: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
