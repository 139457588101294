// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-picker-row {
	margin: 0.5rem;
	display: flex;
	justify-content: space-evenly;
	font-size: 1.4rem;
	font-family: "Inter-Bold", sans-serif;
	font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/LeftPanel/MapWithDate/DateDisplay/DateDisplay.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,aAAa;CACb,6BAA6B;CAC7B,iBAAiB;CACjB,qCAAqC;CACrC,iBAAiB;AAClB","sourcesContent":[".date-picker-row {\n\tmargin: 0.5rem;\n\tdisplay: flex;\n\tjustify-content: space-evenly;\n\tfont-size: 1.4rem;\n\tfont-family: \"Inter-Bold\", sans-serif;\n\tfont-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
