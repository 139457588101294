// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.db-panel.wrapper {
    display:flex;
    flex-direction: column;
	width: 100%;
	flex-grow: 0;
	margin: 2rem;
	position: relative;
    background-color: var(--www-color-float-bg1);
    border-radius:10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ApiWelcomePage/DashboardPanel/DashboardPanel.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;CACzB,WAAW;CACX,YAAY;CACZ,YAAY;CACZ,kBAAkB;IACf,4CAA4C;IAC5C,kBAAkB;AACtB","sourcesContent":[".db-panel.wrapper {\n    display:flex;\n    flex-direction: column;\n\twidth: 100%;\n\tflex-grow: 0;\n\tmargin: 2rem;\n\tposition: relative;\n    background-color: var(--www-color-float-bg1);\n    border-radius:10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
