// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-element.search-icon{
  text-align: right;
  margin-top: -40px;
  z-index: 8001;
  cursor: pointer;
}

.locate-me-icon {
  width: 40px;
  height: 40px;
  padding: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/LeftPanel/MapWithDate/MyMap/SearchLocationIcon/SearchLocationIcon.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;AACd","sourcesContent":[".tooltip-element.search-icon{\n  text-align: right;\n  margin-top: -40px;\n  z-index: 8001;\n  cursor: pointer;\n}\n\n.locate-me-icon {\n  width: 40px;\n  height: 40px;\n  padding: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
