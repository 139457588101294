// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spacer {
	min-height: var(--mobile-nav-height);
}
.left-panel-container {
	width: 100%;
	color: var(--www-color-float-fg1);
}
.left-panel-inner-content {
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 3rem;
	padding-top: 0rem;
	/* margin: 1rem; */
	margin-top: 0vh;
	flex-basis: 100vh;
}
.indicators-container {
	display: flex;
	flex-direction: column;
	padding-top: 1rem;
	width: 100%;
	text-align: left;
}
.indicators-container.inner-content {
	padding-top: 0rem;
}
@media (min-width: 500px) {
	.spacer {
		display: none;
	}
	.left-panel-container {
		position: fixed;
		z-index: 9;
		padding-top: 4rem;
		height: 100%;
		width: 275px;
		max-width: 275px;
		overflow-y: scroll;
		box-shadow: 0px 0px 6px 0px black;
		background: var(--www-color-float-bg1);
		color: var(--www-color-float-fg1);
	}
	.left-panel-inner-content {
		width: 275px;
		max-width: 275px;
		padding: 2rem;
		/* position: absolute; */
		margin: 0rem;
		justify-content: flex-start;
		background-color: transparent;
		border-radius: inherit;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/LeftPanel/LeftPanel.css"],"names":[],"mappings":"AAAA;CACC,oCAAoC;AACrC;AACA;CACC,WAAW;CACX,iCAAiC;AAClC;AACA;CACC,mBAAmB;CACnB,aAAa;CACb,sBAAsB;CACtB,6BAA6B;CAC7B,mBAAmB;CACnB,aAAa;CACb,iBAAiB;CACjB,kBAAkB;CAClB,eAAe;CACf,iBAAiB;AAClB;AACA;CACC,aAAa;CACb,sBAAsB;CACtB,iBAAiB;CACjB,WAAW;CACX,gBAAgB;AACjB;AACA;CACC,iBAAiB;AAClB;AACA;CACC;EACC,aAAa;CACd;CACA;EACC,eAAe;EACf,UAAU;EACV,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,iCAAiC;EACjC,sCAAsC;EACtC,iCAAiC;CAClC;CACA;EACC,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,wBAAwB;EACxB,YAAY;EACZ,2BAA2B;EAC3B,6BAA6B;EAC7B,sBAAsB;CACvB;AACD","sourcesContent":[".spacer {\n\tmin-height: var(--mobile-nav-height);\n}\n.left-panel-container {\n\twidth: 100%;\n\tcolor: var(--www-color-float-fg1);\n}\n.left-panel-inner-content {\n\tborder-radius: 1rem;\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-evenly;\n\talign-items: center;\n\tpadding: 3rem;\n\tpadding-top: 0rem;\n\t/* margin: 1rem; */\n\tmargin-top: 0vh;\n\tflex-basis: 100vh;\n}\n.indicators-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tpadding-top: 1rem;\n\twidth: 100%;\n\ttext-align: left;\n}\n.indicators-container.inner-content {\n\tpadding-top: 0rem;\n}\n@media (min-width: 500px) {\n\t.spacer {\n\t\tdisplay: none;\n\t}\n\t.left-panel-container {\n\t\tposition: fixed;\n\t\tz-index: 9;\n\t\tpadding-top: 4rem;\n\t\theight: 100%;\n\t\twidth: 275px;\n\t\tmax-width: 275px;\n\t\toverflow-y: scroll;\n\t\tbox-shadow: 0px 0px 6px 0px black;\n\t\tbackground: var(--www-color-float-bg1);\n\t\tcolor: var(--www-color-float-fg1);\n\t}\n\t.left-panel-inner-content {\n\t\twidth: 275px;\n\t\tmax-width: 275px;\n\t\tpadding: 2rem;\n\t\t/* position: absolute; */\n\t\tmargin: 0rem;\n\t\tjustify-content: flex-start;\n\t\tbackground-color: transparent;\n\t\tborder-radius: inherit;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
