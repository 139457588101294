// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switcher-arrows {
	position: absolute;
	top: 0%;
	height: 80%;
	z-index: 3000;
	cursor: default;
}

.switcher-arrows.left {
	left: 0%;
	min-width: 3rem;
	min-height: 10rem;
}
.switcher-arrows.right {
	right: 0%;
	min-width: 3rem;
	min-height: 10rem;
}
.switcher-arrow {
	width: 2.5rem;
	height: 2.5rem;
	position: absolute;
	top: 50%;
}
.switcher-arrow.left {
	transform: rotate(180deg);
}
.switcher-arrow.right {
	transform: rotate(0deg);
	right: 0%;
}
`, "",{"version":3,"sources":["webpack://./src/components/panel/Switcher/Switcher.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,OAAO;CACP,WAAW;CACX,aAAa;CACb,eAAe;AAChB;;AAEA;CACC,QAAQ;CACR,eAAe;CACf,iBAAiB;AAClB;AACA;CACC,SAAS;CACT,eAAe;CACf,iBAAiB;AAClB;AACA;CACC,aAAa;CACb,cAAc;CACd,kBAAkB;CAClB,QAAQ;AACT;AACA;CACC,yBAAyB;AAC1B;AACA;CACC,uBAAuB;CACvB,SAAS;AACV","sourcesContent":[".switcher-arrows {\n\tposition: absolute;\n\ttop: 0%;\n\theight: 80%;\n\tz-index: 3000;\n\tcursor: default;\n}\n\n.switcher-arrows.left {\n\tleft: 0%;\n\tmin-width: 3rem;\n\tmin-height: 10rem;\n}\n.switcher-arrows.right {\n\tright: 0%;\n\tmin-width: 3rem;\n\tmin-height: 10rem;\n}\n.switcher-arrow {\n\twidth: 2.5rem;\n\theight: 2.5rem;\n\tposition: absolute;\n\ttop: 50%;\n}\n.switcher-arrow.left {\n\ttransform: rotate(180deg);\n}\n.switcher-arrow.right {\n\ttransform: rotate(0deg);\n\tright: 0%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
