// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-column input {
	margin-bottom: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 1.2rem;
	width: 75%;
	padding: 1rem;
}
.parser-output {
	display: flex;
	flex-direction: column;
	color: black;
}
.add-simulation-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-grow: 0;
	align-items: center;
}
.add-simulation-wrapper input {
	margin-bottom: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 1.2rem;
	width: 50%;
	padding: 1rem;
}
.add-simulation-wrapper input:hover {
	border: 1px solid #0000006b;
	border-radius: 5px;
	font-size: 1.2rem;
	width: 50%;
	padding: 1rem;
}

.add-simulation-wrapper h3 {
	margin-bottom: 20px;
	font-size: 2vw;
}

.add-simulation-wrapper .login-submit-button {
	width: 30%;
	cursor: pointer;
	font-size: 1.3vw;
	color: white;
	background-color: var(--primary-color1);
}

.add-simulation-wrapper .login-submit-button:hover {
	transform: translate3d(-1px, -1px, -2px);
	font-size: 1.3vw;
	color: white;
	background-color: var(--primary-color1);
	box-shadow: 1px 1px 3px 1px rgba(7, 35, 58, 0.404);
}
`, "",{"version":3,"sources":["webpack://./src/pages/ApiWelcomePage/AddSimulation/AddSimulation.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,sBAAsB;CACtB,kBAAkB;CAClB,iBAAiB;CACjB,UAAU;CACV,aAAa;AACd;AACA;CACC,aAAa;CACb,sBAAsB;CACtB,YAAY;AACb;AACA;CACC,aAAa;CACb,sBAAsB;CACtB,WAAW;CACX,YAAY;CACZ,mBAAmB;AACpB;AACA;CACC,mBAAmB;CACnB,sBAAsB;CACtB,kBAAkB;CAClB,iBAAiB;CACjB,UAAU;CACV,aAAa;AACd;AACA;CACC,2BAA2B;CAC3B,kBAAkB;CAClB,iBAAiB;CACjB,UAAU;CACV,aAAa;AACd;;AAEA;CACC,mBAAmB;CACnB,cAAc;AACf;;AAEA;CACC,UAAU;CACV,eAAe;CACf,gBAAgB;CAChB,YAAY;CACZ,uCAAuC;AACxC;;AAEA;CACC,wCAAwC;CACxC,gBAAgB;CAChB,YAAY;CACZ,uCAAuC;CACvC,kDAAkD;AACnD","sourcesContent":[".flex-column input {\n\tmargin-bottom: 20px;\n\tborder: 1px solid #ccc;\n\tborder-radius: 5px;\n\tfont-size: 1.2rem;\n\twidth: 75%;\n\tpadding: 1rem;\n}\n.parser-output {\n\tdisplay: flex;\n\tflex-direction: column;\n\tcolor: black;\n}\n.add-simulation-wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: 100%;\n\tflex-grow: 0;\n\talign-items: center;\n}\n.add-simulation-wrapper input {\n\tmargin-bottom: 20px;\n\tborder: 1px solid #ccc;\n\tborder-radius: 5px;\n\tfont-size: 1.2rem;\n\twidth: 50%;\n\tpadding: 1rem;\n}\n.add-simulation-wrapper input:hover {\n\tborder: 1px solid #0000006b;\n\tborder-radius: 5px;\n\tfont-size: 1.2rem;\n\twidth: 50%;\n\tpadding: 1rem;\n}\n\n.add-simulation-wrapper h3 {\n\tmargin-bottom: 20px;\n\tfont-size: 2vw;\n}\n\n.add-simulation-wrapper .login-submit-button {\n\twidth: 30%;\n\tcursor: pointer;\n\tfont-size: 1.3vw;\n\tcolor: white;\n\tbackground-color: var(--primary-color1);\n}\n\n.add-simulation-wrapper .login-submit-button:hover {\n\ttransform: translate3d(-1px, -1px, -2px);\n\tfont-size: 1.3vw;\n\tcolor: white;\n\tbackground-color: var(--primary-color1);\n\tbox-shadow: 1px 1px 3px 1px rgba(7, 35, 58, 0.404);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
