// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-base {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	font-size: 2.5rem;
}

.login-logo {
	position: absolute;
	width: 200px;
	height: 200px;
	left: 0;
	top: 0;
	margin-bottom: 20px;
}
.email-field,
.password-field {
	width: 300px;
	height: 40px;
	padding: 10px;
	margin-bottom: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 1.2rem;
}

.email-field {
	/* Add specific styles for email field */
}

.password-field {
	/* Add specific styles for username field */
}
.checkbox-field {
	margin-bottom: 20px;
	font-size: 1.2rem;
}
.login-submit-button {
	width: 300px;
	height: 40px;
	border: none;
	border-radius: 5px;
	background-color: #007bff;
	color: white;
	font-size: 1.4rem;
	cursor: pointer;
}

.register-text {
	font-size: 1.4rem;
	margin-top: 20px;
	margin: 1rem;
}
.login-boxes {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	font-size: 2.5rem;
	position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/pages/LoginRegister/LoginPage/loginPage.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,uBAAuB;CACvB,YAAY;CACZ,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,aAAa;CACb,OAAO;CACP,MAAM;CACN,mBAAmB;AACpB;AACA;;CAEC,YAAY;CACZ,YAAY;CACZ,aAAa;CACb,mBAAmB;CACnB,sBAAsB;CACtB,kBAAkB;CAClB,iBAAiB;AAClB;;AAEA;CACC,wCAAwC;AACzC;;AAEA;CACC,2CAA2C;AAC5C;AACA;CACC,mBAAmB;CACnB,iBAAiB;AAClB;AACA;CACC,YAAY;CACZ,YAAY;CACZ,YAAY;CACZ,kBAAkB;CAClB,yBAAyB;CACzB,YAAY;CACZ,iBAAiB;CACjB,eAAe;AAChB;;AAEA;CACC,iBAAiB;CACjB,gBAAgB;CAChB,YAAY;AACb;AACA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,uBAAuB;CACvB,YAAY;CACZ,iBAAiB;CACjB,kBAAkB;AACnB","sourcesContent":[".login-base {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\theight: 100%;\n\tfont-size: 2.5rem;\n}\n\n.login-logo {\n\tposition: absolute;\n\twidth: 200px;\n\theight: 200px;\n\tleft: 0;\n\ttop: 0;\n\tmargin-bottom: 20px;\n}\n.email-field,\n.password-field {\n\twidth: 300px;\n\theight: 40px;\n\tpadding: 10px;\n\tmargin-bottom: 20px;\n\tborder: 1px solid #ccc;\n\tborder-radius: 5px;\n\tfont-size: 1.2rem;\n}\n\n.email-field {\n\t/* Add specific styles for email field */\n}\n\n.password-field {\n\t/* Add specific styles for username field */\n}\n.checkbox-field {\n\tmargin-bottom: 20px;\n\tfont-size: 1.2rem;\n}\n.login-submit-button {\n\twidth: 300px;\n\theight: 40px;\n\tborder: none;\n\tborder-radius: 5px;\n\tbackground-color: #007bff;\n\tcolor: white;\n\tfont-size: 1.4rem;\n\tcursor: pointer;\n}\n\n.register-text {\n\tfont-size: 1.4rem;\n\tmargin-top: 20px;\n\tmargin: 1rem;\n}\n.login-boxes {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\theight: 100%;\n\tfont-size: 2.5rem;\n\tposition: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
