// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-descriptions-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 0px;
    width: 100%;

}
.description-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/vectorSelection/ChangeMapPanel/ChangeMapPanel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,YAAY;IACZ,cAAc;IACd,WAAW;;AAEf;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".map-descriptions-wrapper{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 100%;\n    padding: 0 0px;\n    width: 100%;\n\n}\n.description-row{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
