// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simlist-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.scrollable-list {
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	overflow-y: scroll;
	align-items: center;
	width:90%
}

.title-simulations {
	font-size: 1.8rem;
	color: var(--primary-color1);
	background-color: var(--www-color-float-bg1);
	border-bottom: 1px solid var(--neutral-color1);
	width: 100%;
	text-align: center;
	border-radius: 10px 10px 0px 0px;
}
.title-simulations h3 {
	margin: 0px;
	padding: 10px;
}

.tile:hover {
	filter: drop-shadow(0px 0px 10px var(--www-color-float-fg1));
}





.flex-row textarea {
	width: 100%;
	height: 100px;
	border: none;
	box-shadow: 0px 0px 5px var(--neutral-color1);
}
`, "",{"version":3,"sources":["webpack://./src/pages/ApiWelcomePage/SimulationList/SimulationList.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,mBAAmB;CACnB,WAAW;CACX,YAAY;AACb;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,SAAS;CACT,uBAAuB;CACvB,kBAAkB;CAClB,mBAAmB;CACnB;AACD;;AAEA;CACC,iBAAiB;CACjB,4BAA4B;CAC5B,4CAA4C;CAC5C,8CAA8C;CAC9C,WAAW;CACX,kBAAkB;CAClB,gCAAgC;AACjC;AACA;CACC,WAAW;CACX,aAAa;AACd;;AAEA;CACC,4DAA4D;AAC7D;;;;;;AAMA;CACC,WAAW;CACX,aAAa;CACb,YAAY;CACZ,6CAA6C;AAC9C","sourcesContent":[".simlist-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\twidth: 100%;\n\theight: 100%;\n}\n\n.scrollable-list {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 10px;\n\tjustify-content: center;\n\toverflow-y: scroll;\n\talign-items: center;\n\twidth:90%\n}\n\n.title-simulations {\n\tfont-size: 1.8rem;\n\tcolor: var(--primary-color1);\n\tbackground-color: var(--www-color-float-bg1);\n\tborder-bottom: 1px solid var(--neutral-color1);\n\twidth: 100%;\n\ttext-align: center;\n\tborder-radius: 10px 10px 0px 0px;\n}\n.title-simulations h3 {\n\tmargin: 0px;\n\tpadding: 10px;\n}\n\n.tile:hover {\n\tfilter: drop-shadow(0px 0px 10px var(--www-color-float-fg1));\n}\n\n\n\n\n\n.flex-row textarea {\n\twidth: 100%;\n\theight: 100px;\n\tborder: none;\n\tbox-shadow: 0px 0px 5px var(--neutral-color1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
