// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-div-wrapper {
	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 5000;
	background-color: #000000a2;
	color: white;
	width: 100vw;
	/* height: 20px; */
}



.error-inner-div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.error-inner-div h3{
	text-align: center;
	font-size: 2.4rem;
	line-height: 3.5rem;
}
.error-inner-div p {
	text-align: center;

	font-size: 1.6rem;
	line-height: 2.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/map/errorScreen/errorScreenMap.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,WAAW;CACX,SAAS;CACT,aAAa;CACb,2BAA2B;CAC3B,YAAY;CACZ,YAAY;CACZ,kBAAkB;AACnB;;;;AAIA;CACC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,kBAAkB;CAClB,iBAAiB;CACjB,mBAAmB;AACpB;AACA;CACC,kBAAkB;;CAElB,iBAAiB;CACjB,mBAAmB;AACpB","sourcesContent":[".error-div-wrapper {\n\tposition: absolute;\n\tbottom: 0px;\n\tleft: 0px;\n\tz-index: 5000;\n\tbackground-color: #000000a2;\n\tcolor: white;\n\twidth: 100vw;\n\t/* height: 20px; */\n}\n\n\n\n.error-inner-div {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.error-inner-div h3{\n\ttext-align: center;\n\tfont-size: 2.4rem;\n\tline-height: 3.5rem;\n}\n.error-inner-div p {\n\ttext-align: center;\n\n\tfont-size: 1.6rem;\n\tline-height: 2.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
