// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-consent {
	align-self: flex-end;
	box-shadow: 1px 1px 3px 1px rgb(60, 60, 60);
	height: 3rem;
	width: 10rem;
	align-items: center;
	justify-content: center;
    color: white !important;

	border: 1px solid var(--primary-color1) !important;
	border-radius: 2rem !important;
    background-color: var(--primary-color1) !important; 
}
.decline{
	height: 3rem;
	width: 10rem;
	border: 1px solid var(--primary-color1) !important;
	border-radius: 2rem !important;
	color: var(--primary-color1) !important;
    background-color: transparent !important; 
}
.button-consent:hover{
    background-color: var(--secondary-color1)  !important;
}
.decline:hover{
	border-color: var(--primary-color2) !important;
    color: var(--primary-color2) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/cookieConsent/consentPlugin.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB,2CAA2C;CAC3C,YAAY;CACZ,YAAY;CACZ,mBAAmB;CACnB,uBAAuB;IACpB,uBAAuB;;CAE1B,kDAAkD;CAClD,8BAA8B;IAC3B,kDAAkD;AACtD;AACA;CACC,YAAY;CACZ,YAAY;CACZ,kDAAkD;CAClD,8BAA8B;CAC9B,uCAAuC;IACpC,wCAAwC;AAC5C;AACA;IACI,qDAAqD;AACzD;AACA;CACC,8CAA8C;IAC3C,uCAAuC;AAC3C","sourcesContent":[".button-consent {\n\talign-self: flex-end;\n\tbox-shadow: 1px 1px 3px 1px rgb(60, 60, 60);\n\theight: 3rem;\n\twidth: 10rem;\n\talign-items: center;\n\tjustify-content: center;\n    color: white !important;\n\n\tborder: 1px solid var(--primary-color1) !important;\n\tborder-radius: 2rem !important;\n    background-color: var(--primary-color1) !important; \n}\n.decline{\n\theight: 3rem;\n\twidth: 10rem;\n\tborder: 1px solid var(--primary-color1) !important;\n\tborder-radius: 2rem !important;\n\tcolor: var(--primary-color1) !important;\n    background-color: transparent !important; \n}\n.button-consent:hover{\n    background-color: var(--secondary-color1)  !important;\n}\n.decline:hover{\n\tborder-color: var(--primary-color2) !important;\n    color: var(--primary-color2) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
