// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-panel {
	z-index: 9;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ApiWelcomePage/InfoPanel/InfoPanel.css"],"names":[],"mappings":"AAAA;CACC,UAAU;CACV,YAAY;CACZ,WAAW;CACX,kBAAkB;CAClB,eAAe;AAChB","sourcesContent":[".info-panel {\n\tz-index: 9;\n\theight: 100%;\n\twidth: 100%;\n\toverflow-y: scroll;\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
