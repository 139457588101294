// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
@media (min-width: 500px) {
	.page-wrapper {
		margin-left: 275px;
		height: 95vh;
		background: none;
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/GenericPage/DesktopContentWrapper.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,8BAA8B;AAC/B;AACA;CACC;EACC,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;CACjB;AACD","sourcesContent":[".page-wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-between;\n}\n@media (min-width: 500px) {\n\t.page-wrapper {\n\t\tmargin-left: 275px;\n\t\theight: 95vh;\n\t\tbackground: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
