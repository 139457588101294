import "./AddSimulation.css";
import { useCreateSimulationMutation } from "store";
import useCsrf from "pages/LoginRegister/Services/useCsrf";
import { useDispatch, useSelector } from "react-redux";

import { setAddedSimulation } from "store";
import { useEffect, useRef } from "react";
function AddSimulation() {
	useCsrf();
	const dispatch = useDispatch();

	const userDetails = useSelector((state) => state.login.apiRegisterResponse);
	const addedSimulation = useSelector(
		(state) => state.simulation.addedSimulation
	);
	const [createSimulation] = useCreateSimulationMutation();
	const setTerm = ({ e, term }) => {
		let localTerm = { ...addedSimulation };
		localTerm[term] = e.target.value;
		dispatch(setAddedSimulation(localTerm));
	};

	const errRef = useRef("");
	useEffect(() => {
		addedSimulation ||
			dispatch(setAddedSimulation({ title: "", description: "", popJson: "" }));
	}, [addedSimulation, dispatch]);

	let envir = {
		photoperiod: [
			0.369003, 0.369568, 0.370186, 0.370856, 0.371577, 0.372348, 0.373169,
			0.374039, 0.374957, 0.375922, 0.376933, 0.37799, 0.379091, 0.380235,
			0.381422, 0.38265, 0.383919, 0.385226, 0.386572, 0.387955, 0.389374,
			0.390829, 0.392317, 0.393838, 0.395391, 0.396975, 0.398589, 0.400231,
			0.401901, 0.403598, 0.405321, 0.407069, 0.40884, 0.410634, 0.412451,
			0.414288, 0.416146, 0.418023, 0.419919, 0.421832, 0.423762, 0.425709,
			0.427671, 0.429648, 0.431638, 0.433642, 0.435659, 0.437688, 0.439728,
			0.441779, 0.44384, 0.445911, 0.447991, 0.45008, 0.452176, 0.454281,
			0.456392, 0.458511, 0.460635, 0.462766, 0.464901, 0.467042, 0.469188,
			0.471338, 0.473492, 0.475649, 0.47781, 0.479974, 0.48214, 0.484309,
			0.48648, 0.488652, 0.490827, 0.493002, 0.495178, 0.497355, 0.499533,
			0.501711, 0.503888, 0.506066, 0.508242, 0.510418, 0.512593, 0.514767,
			0.516939, 0.519109, 0.521278, 0.523443, 0.525607, 0.527768, 0.529925,
			0.532079, 0.53423, 0.536377, 0.538519, 0.540657, 0.542791, 0.544919,
			0.547042, 0.549159, 0.55127, 0.553374, 0.555472, 0.557562, 0.559645,
			0.561721, 0.563787, 0.565845, 0.567894, 0.569933, 0.571962, 0.57398,
			0.575987, 0.577983, 0.579966, 0.581937, 0.583894, 0.585838, 0.587767,
			0.589681, 0.591579, 0.593461, 0.595326, 0.597174, 0.599003, 0.600813,
			0.602603, 0.604372, 0.60612, 0.607846, 0.609549, 0.611229, 0.612884,
			0.614513, 0.616116, 0.617693, 0.619241, 0.620761, 0.622251, 0.62371,
			0.625138, 0.626534, 0.627896, 0.629225, 0.630518, 0.631776, 0.632997,
			0.63418, 0.635325, 0.636431, 0.637496, 0.638521, 0.639504, 0.640444,
			0.641341, 0.642194, 0.643002, 0.643765, 0.644481, 0.645151, 0.645774,
			0.646349, 0.646876, 0.647354, 0.647783, 0.648162, 0.648492, 0.648771,
			0.649, 0.649178, 0.649305, 0.649381, 0.649407, 0.649381, 0.649305,
			0.649178, 0.649, 0.648772, 0.648493, 0.648164, 0.647786, 0.647359,
			0.646882, 0.646358, 0.645785, 0.645165, 0.644498, 0.643785, 0.643026,
			0.642223, 0.641375, 0.640484, 0.63955, 0.638575, 0.637558, 0.6365,
			0.635404, 0.634268, 0.633095, 0.631885, 0.630639, 0.629357, 0.628042,
			0.626693, 0.625311, 0.623898, 0.622454, 0.62098, 0.619477, 0.617946,
			0.616388, 0.614803, 0.613193, 0.611558, 0.609899, 0.608217, 0.606513,
			0.604787, 0.603041, 0.601274, 0.599488, 0.597683, 0.595861, 0.594021,
			0.592165, 0.590293, 0.588405, 0.586503, 0.584587, 0.582658, 0.580716,
			0.578761, 0.576794, 0.574816, 0.572828, 0.570829, 0.56882, 0.566801,
			0.564774, 0.562738, 0.560694, 0.558643, 0.556584, 0.554517, 0.552445,
			0.550366, 0.548281, 0.54619, 0.544095, 0.541994, 0.539888, 0.537778,
			0.535664, 0.533546, 0.531424, 0.5293, 0.527172, 0.525041, 0.522907,
			0.520771, 0.518633, 0.516494, 0.514352, 0.512209, 0.510065, 0.50792,
			0.505774, 0.503628, 0.501481, 0.499334, 0.497188, 0.495041, 0.492896,
			0.490751, 0.488607, 0.486465, 0.484325, 0.482186, 0.48005, 0.477916,
			0.475784, 0.473656, 0.471531, 0.46941, 0.467293, 0.46518, 0.463072,
			0.460968, 0.458871, 0.456779, 0.454693, 0.452614, 0.450543, 0.448478,
			0.446422, 0.444375, 0.442336, 0.440307, 0.438288, 0.43628, 0.434283,
			0.432298, 0.430325, 0.428366, 0.42642, 0.424489, 0.422574, 0.420674,
			0.418791, 0.416926, 0.415079, 0.413251, 0.411443, 0.409656, 0.40789,
			0.406148, 0.404429, 0.402734, 0.401065, 0.399422, 0.397807, 0.396221,
			0.394663, 0.393137, 0.391642, 0.390179, 0.38875, 0.387356, 0.385998,
			0.384677, 0.383393, 0.382148, 0.380944, 0.37978, 0.378658, 0.37758,
			0.376545, 0.375556, 0.374612, 0.373716, 0.372867, 0.372067, 0.371316,
			0.370615, 0.369965, 0.369367, 0.368821, 0.368328, 0.367889, 0.367503,
			0.367172, 0.366895, 0.366674, 0.366508, 0.366397, 0.366342, 0.366343,
			0.3664, 0.366512, 0.36668, 0.366904, 0.367182, 0.367516, 0.367904,
			0.368347,
		],
		"2m_temperature": [
			7.79451, 8.51764, 6.67975, 5.43353, 4.63713, 4.88981, 6.80322, 7.25344,
			7.91248, 7.79176, 8.75516, 10.4752, 6.41459, 7.16861, 6.47053, 5.46167,
			6.05526, 7.63449, 13.2127, 9.39787, 9.7219, 8.59565, 9.22279, 5.98517,
			2.00001, 1.26405, 2.17792, 4.52224, 6.99519, 4.16534, 3.34155, 4.9619,
			3.93473, 5.04482, 3.56508, 4.21596, 5.54176, 5.88568, 6.67583, 7.77836,
			8.34189, 7.2785, 6.21519, 11.208, 7.88425, 9.02116, 6.47003, 5.53726,
			5.51154, 5.07804, 6.24916, 8.77597, 9.73846, 8.21476, 9.30055, 9.47155,
			9.13246, 10.7752, 10.2536, 11.2101, 12.8659, 12.9161, 12.3514, 11.8055,
			11.7449, 11.1758, 11.2972, 11.252, 10.1979, 9.39113, 8.835, 10.6814,
			12.2163, 12.6375, 12.2141, 13.8987, 13.4473, 11.2839, 3.10087, 6.08923,
			6.19455, 6.27047, 7.56265, 6.27214, 7.29415, 7.96668, 8.6466, 9.1323,
			7.46799, 8.20752, 10.9005, 12.0378, 12.1332, 11.1892, 11.8873, 11.6494,
			13.3889, 14.1351, 14.6391, 15.2904, 15.7879, 17.0537, 16.1569, 16.9679,
			17.6413, 16.8478, 16.7042, 17.4, 15.4885, 16.3226, 16.9393, 17.9288,
			19.1029, 20.3385, 19.4308, 17.7919, 17.8174, 18.4829, 19.1927, 16.6684,
			16.766, 13.6379, 13.4251, 13.3472, 13.8702, 15.535, 18.3132, 20.5055,
			22.1762, 20.8845, 20.0817, 20.8781, 20.9407, 21.143, 18.2968, 16.7644,
			17.7149, 18.5525, 18.7418, 21.3845, 23.3701, 24.7339, 24.9624, 25.3192,
			24.6317, 22.7179, 20.5917, 14.8618, 13.9115, 17.5116, 17.4527, 15.3851,
			14.5105, 18.2217, 20.2833, 19.6634, 17.7928, 17.9366, 20.2562, 22.3346,
			22.9711, 22.5511, 21.4666, 22.2064, 23.1511, 22.5232, 22.5182, 22.4441,
			23.112, 24.8156, 26.6782, 26.3192, 24.4041, 23.6641, 24.2145, 25.4082,
			23.2562, 21.0438, 20.8161, 22.5669, 23.6622, 24.9098, 23.848, 22.8869,
			23.2806, 21.4106, 23.6716, 24.8237, 25.8891, 24.9969, 22.6055, 20.3141,
			22.4647, 23.8962, 26.0691, 27.2024, 27.6498, 27.8804, 28.4033, 28.6581,
			28.5671, 27.5747, 26.0268, 26.3653, 25.6704, 25.2947, 26.0104, 27.8347,
			28.2614, 26.985, 26.0539, 21.5935, 21.8566, 24.152, 22.0612, 23.0936,
			23.7893, 24.343, 25.3847, 22.5514, 18.1976, 20.1397, 23.1717, 24.0698,
			24.4261, 24.9081, 26.3179, 26.4637, 25.4158, 23.4099, 24.1856, 20.4267,
			17.2184, 19.1143, 19.5081, 20.3298, 23.2661, 25.8108, 26.8599, 26.7982,
			25.3005, 22.528, 18.9119, 20.2019, 20.7357, 21.5839, 18.1885, 16.0888,
			15.8051, 18.4487, 19.5974, 20.2347, 20.276, 20.8563, 19.0164, 19.1775,
			20.0249, 20.6929, 21.6084, 21.14, 21.7196, 17.4708, 15.2654, 16.9584,
			18.2392, 19.7214, 19.8019, 18.7678, 14.1763, 12.5043, 13.4713, 16.4776,
			16.3574, 16.6769, 17.7618, 19.1977, 19.7584, 19.2778, 18.0874, 16.1453,
			15.1454, 14.8569, 14.7888, 14.4563, 14.8321, 14.678, 12.8756, 12.3182,
			11.5969, 11.8154, 11.8523, 11.0416, 7.52127, 7.04848, 8.70039, 11.3909,
			8.81602, 8.92383, 10.2885, 12.8974, 13.6178, 12.3544, 10.2902, 10.4858,
			9.71907, 8.71479, 9.70474, 9.59668, 9.37906, 10.2198, 9.37631, 10.5182,
			10.1503, 10.1267, 12.1163, 10.3803, 6.38112, 5.48423, 5.51454, 3.63468,
			2.10882, 3.07106, 3.63044, 3.87453, 4.66744, 6.3807, 8.83633, 9.02757,
			10.0907, 9.71498, 7.34285, 7.18643, 5.92323, 5.24396, 6.21486, 8.14816,
			9.68335, 8.24274, 5.57398, 5.56674, 5.89708, 6.26074, 4.26, 2.95134,
			3.42139, 4.73487, 4.52474, 2.72914, 1.41674, 0.535502, 0.761539,
			-0.389352, 1.09538, 1.63287, 1.46594, 1.84125, 3.48874, 3.74266, 3.0654,
			3.08937, 2.09958, 1.45712, -0.0236244, -0.612972, 1.99926,
		],
		total_precipitation: [
			0.0211326, 0.1335, 0.0, 0.000429495, 0.000643854, 0.000375478, 0.0011269,
			0.026121, 0.00938633, 0.0271938, 0.0060612, 0.000858214, 0.000536442,
			0.00295059, 0.00654425, 0.0114247, 0.0129802, 0.00461346, 0.000161119,
			0.0, 0.00177013, 0.0254237, 0.611664, 0.089197, 0.541992, 0.112046,
			5.37063e-5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.00268205, 0.0, 0.000268376, 0.0,
			0.325464, 0.156403, 0.205265, 0.113279, 0.0202746, 0.0, 0.501067,
			0.00493461, 0.0180217, 0.078416, 0.0, 0.00101918, 0.0352929, 5.37063e-5,
			0.0, 0.0385108, 0.0558354, 0.0577662, 0.0480044, 0.10754, 0.000536442,
			0.0, 5.37063e-5, 0.00767037, 0.0018777, 0.000590148, 0.0, 0.00305753,
			0.0503644, 0.227739, 0.0433917, 0.00418381, 0.0, 0.000375478, 0.0, 0.0,
			0.0, 0.0, 0.000268221, 0.0, 5.37063e-5, 0.197166, 0.873301, 0.0319673,
			0.0224202, 0.0, 0.127171, 2.09861, 0.960567, 0.16359, 0.0040764,
			0.0132484, 0.281482, 0.100192, 0.118322, 0.0046667, 0.0512226, 0.17126,
			0.000536442, 0.0, 0.000107257, 0.00595363, 0.0138919, 0.0, 0.00101918,
			0.0, 5.37063e-5, 0.00225287, 0.0, 0.0, 0.0, 0.0219909, 0.0279446,
			0.00493477, 0.0319672, 0.0346489, 0.0263353, 0.0, 0.0152864, 0.00911811,
			0.00413011, 0.0, 0.000643699, 0.0698882, 0.0275693, 0.90205, 0.258097,
			0.981968, 0.42351, 0.00691942, 0.000482736, 0.0, 0.0, 0.00112643, 0.0,
			0.00101933, 0.0, 0.000107413, 0.0184509, 0.00525638, 0.00230658,
			0.00198449, 0.0, 0.0, 0.0, 0.0, 0.00300367, 0.00413011, 0.0514369,
			0.0273011, 0.110437, 0.84954, 0.117999, 0.000321772, 0.0363655, 0.341715,
			0.615687, 0.0630226, 0.15533, 0.337853, 0.637839, 1.65558, 0.0285885,
			0.00091192, 0.00235997, 0.00466639, 0.0398516, 0.0471997, 0.000161119,
			0.00214546, 0.00622186, 0.0297145, 0.00193094, 0.0047201, 0.00370108,
			0.00042934, 0.000268221, 0.0, 0.0, 0.0, 0.0, 0.100514, 0.011639, 0.0,
			0.00466655, 0.00488106, 0.0149646, 0.00804554, 0.0875341, 0.0, 0.0, 0.0,
			0.0, 0.000107413, 0.085603, 0.000536287, 0.000375478, 0.0, 0.0, 0.0, 0.0,
			0.0, 0.0, 0.0, 0.0, 0.0, 0.00171643, 0.0, 0.0305725, 0.000804663, 0.0,
			0.0, 5.37063e-5, 0.057659, 0.0417287, 0.0, 0.0, 0.0, 0.239109,
			0.000107413, 0.0, 0.0, 0.0278372, 0.316345, 0.557868, 0.0260676, 0.0,
			0.00021467, 0.0261213, 0.00176998, 0.0, 0.0, 0.00112643, 0.01652,
			0.0115319, 0.245117, 0.27762, 0.33206, 0.0522955, 0.0136237, 0.0, 0.0,
			0.0, 0.00364737, 0.00842102, 1.01715, 0.870941, 5.37063e-5, 0.000375478,
			0.00364721, 0.208376, 0.00144821, 0.0, 0.0, 0.00069725, 0.00246738,
			0.0244584, 0.00327205, 0.0, 0.0, 0.0, 0.0, 0.0107274, 0.216636,
			0.00992278, 0.0025748, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.889714, 0.480203,
			0.0111029, 0.00579298, 0.00230673, 0.00236013, 0.0, 0.00230642, 0.0572295,
			0.00166288, 0.496455, 0.0143745, 0.0, 0.0, 0.000321772, 0.00209206, 0.0,
			0.00021467, 0.0, 0.0, 0.0, 0.0, 0.0603405, 0.00262819, 0.215563, 0.0,
			0.0133556, 0.0, 0.496938, 0.380494, 0.768925, 0.0343813, 0.0506858,
			0.0286419, 1.7421, 0.447807, 0.0, 0.0, 0.0, 0.0, 0.00477365, 0.0312168,
			0.0, 0.0, 0.0197918, 0.0, 0.0, 0.00418381, 0.0, 0.0521345, 0.0159838,
			0.00021467, 0.0, 0.0, 0.0, 0.00123354, 0.0273547, 0.701988, 0.551646,
			0.815267, 0.0025748, 0.000322082, 0.0204894, 0.00321896, 0.000912075,
			0.000160964, 0.00123385, 0.00622201, 0.0200601, 0.0, 0.0, 0.0, 0.0145355,
			0.130979, 0.219157, 0.000590148, 0.00101918, 0.0, 0.0, 0.0, 0.0118542,
			0.0559427, 0.0350781, 0.0094402, 0.0, 0.0, 0.0, 0.0540654, 0.392937,
			0.0551919, 0.0308409, 0.00627572, 0.0, 5.37063e-5, 0.000268221, 0.0505253,
			0.0,
		],
		popdens: [
			312.276, 312.287, 312.297, 312.307, 312.318, 312.328, 312.338, 312.349,
			312.359, 312.37, 312.38, 312.39, 312.401, 312.411, 312.421, 312.432,
			312.442, 312.452, 312.463, 312.473, 312.484, 312.494, 312.504, 312.515,
			312.525, 312.535, 312.546, 312.556, 312.566, 312.577, 312.587, 312.597,
			312.608, 312.618, 312.628, 312.639, 312.649, 312.659, 312.67, 312.68,
			312.69, 312.701, 312.711, 312.721, 312.732, 312.742, 312.752, 312.763,
			312.773, 312.783, 312.794, 312.804, 312.814, 312.825, 312.835, 312.845,
			312.856, 312.866, 312.876, 312.887, 312.897, 312.907, 312.918, 312.928,
			312.938, 312.948, 312.959, 312.969, 312.979, 312.99, 313.0, 313.01,
			313.021, 313.031, 313.041, 313.051, 313.062, 313.072, 313.082, 313.093,
			313.103, 313.113, 313.124, 313.134, 313.144, 313.154, 313.165, 313.175,
			313.185, 313.196, 313.206, 313.216, 313.226, 313.237, 313.247, 313.257,
			313.268, 313.278, 313.288, 313.298, 313.309, 313.319, 313.329, 313.34,
			313.35, 313.36, 313.37, 313.381, 313.391, 313.401, 313.411, 313.422,
			313.432, 313.442, 313.452, 313.463, 313.473, 313.483, 313.493, 313.504,
			313.514, 313.524, 313.535, 313.545, 313.555, 313.565, 313.576, 313.586,
			313.596, 313.606, 313.617, 313.627, 313.637, 313.647, 313.657, 313.668,
			313.678, 313.688, 313.698, 313.709, 313.719, 313.729, 313.739, 313.75,
			313.76, 313.77, 313.78, 313.791, 313.801, 313.811, 313.821, 313.831,
			313.842, 313.852, 313.862, 313.872, 313.883, 313.893, 313.903, 313.913,
			313.923, 313.934, 313.944, 313.954, 313.964, 313.975, 313.985, 313.995,
			314.005, 314.015, 314.026, 314.036, 314.046, 314.056, 314.066, 314.077,
			314.087, 314.097, 314.107, 314.117, 314.128, 314.138, 314.148, 314.158,
			314.168, 314.179, 314.189, 314.199, 314.209, 314.219, 314.229, 314.24,
			314.25, 314.26, 314.27, 314.28, 314.291, 314.301, 314.311, 314.321,
			314.331, 314.341, 314.352, 314.362, 314.372, 314.382, 314.392, 314.402,
			314.413, 314.423, 314.433, 314.443, 314.453, 314.463, 314.474, 314.484,
			314.494, 314.504, 314.514, 314.524, 314.535, 314.545, 314.555, 314.565,
			314.575, 314.585, 314.595, 314.606, 314.616, 314.626, 314.636, 314.646,
			314.656, 314.667, 314.677, 314.687, 314.697, 314.707, 314.717, 314.727,
			314.737, 314.748, 314.758, 314.768, 314.778, 314.788, 314.798, 314.808,
			314.819, 314.829, 314.839, 314.849, 314.859, 314.869, 314.879, 314.889,
			314.9, 314.91, 314.92, 314.93, 314.94, 314.95, 314.96, 314.97, 314.98,
			314.991, 315.001, 315.011, 315.021, 315.031, 315.041, 315.051, 315.061,
			315.071, 315.081, 315.092, 315.102, 315.112, 315.122, 315.132, 315.142,
			315.152, 315.162, 315.172, 315.182, 315.192, 315.203, 315.213, 315.223,
			315.233, 315.243, 315.253, 315.263, 315.273, 315.283, 315.293, 315.303,
			315.313, 315.324, 315.334, 315.344, 315.354, 315.364, 315.374, 315.384,
			315.394, 315.404, 315.414, 315.424, 315.434, 315.444, 315.454, 315.464,
			315.475, 315.485, 315.495, 315.505, 315.515, 315.525, 315.535, 315.545,
			315.555, 315.565, 315.575, 315.585, 315.595, 315.605, 315.615, 315.625,
			315.635, 315.645, 315.655, 315.665, 315.675, 315.686, 315.696, 315.706,
			315.716, 315.726, 315.736, 315.746, 315.756, 315.766, 315.776, 315.786,
			315.796, 315.806, 315.816, 315.826, 315.836, 315.846, 315.856, 315.866,
			315.876, 315.886, 315.896, 315.906, 315.916, 315.926, 315.936, 315.946,
			315.956, 315.966, 315.976, 315.986,
		],
	};
	let myjson = { envir: envir };
	const handleAddSimulation = async () => {
		try {
			let data = {
				title: addedSimulation.title,
				description: addedSimulation.description,
				return_method: addedSimulation.returnMethod,
				user: "",
				model_type: addedSimulation.modelType,
				model_data: JSON.parse(addedSimulation.modelData),
				pop_json: addedSimulation.popJson,
			};
			console.log({ data });
			const response = await createSimulation(data);
			console.log({ resSSSponse: response });
			if (response.error) {
				console.log("error in response", response.error.data);
				throw new Error(response.error.data.title);
			}
			dispatch(
				setAddedSimulation({
					title: "",
					description: "",
					popJson: null,
					modelType: "",
					returnMethod: "",
					modelData: "",
				})
			);
		} catch (e) {
			errRef.current = e.message;
		}
	};

	return (
		<div className="add-simulation-wrapper">
			{" "}
			<div>
				<h3 className="meteo-text">Add a simulation</h3>
			</div>
			<input
				onChange={(e) => setTerm({ e: e, term: "title" })}
				value={addedSimulation.title}
				type="title"
				placeholder="title"
			></input>
			<input
				onChange={(e) => setTerm({ e: e, term: "description" })}
				value={addedSimulation.description}
				type="description"
				placeholder="description"
			></input>
			<input
				id="return_method"
				onChange={(e) => setTerm({ e: e, term: "returnMethod" })}
				value={addedSimulation.returnMethod}
				type="text"
				placeholder="return method"
			></input>
			<input
				id="model_type"
				onChange={(e) => setTerm({ e: e, term: "modelType" })}
				value={addedSimulation.modelType}
				type="text"
				placeholder="model type"
			></input>
			<textarea
				id="model_data"
				onChange={(e) => setTerm({ e: e, term: "modelData" })}
				value={addedSimulation.modelData}
				rows={4}
				cols={50}
				placeholder="model data"
			></textarea>
			<textarea
				id="popJson"
				onChange={(e) => setTerm({ e: e, term: "popJson" })}
				value={addedSimulation.popJson}
				rows={4}
				cols={50}
				wrap="hard"
				placeholder="pop json"
			></textarea>
			<button
				className="login-submit-button  mb10"
				onClick={handleAddSimulation}
			>
				Add simulation
			</button>
		</div>
	);
}

export default AddSimulation;
