// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circular-progressbar {
	position: relative;
	border: black !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	pointer-events: none;
	z-index: 2000;
}
.circular-progressbar.wrapper.single {
	position: absolute;
	top: calc(50vh - 100px);
	left: calc(50vw - 100px);
	/* transform: translate(-50%, -50%); */
	z-index: 5000;
}
.circular-progressbar.wrapper.left {
	position: absolute;
	top: calc(50vh - 100px);
	left: calc(25vw - 100px);
	/* transform: translate(-50%, -50%); */
	z-index: 5000;
}
.circular-progressbar.wrapper.right {
	position: absolute;
	top: calc(50vh - 100px);
	right: calc(25vw - 100px);
	/* transform: translate(-50%, -50%); */
	z-index: 5000;
}
.circular-progressbar.text {
	text-align: center;
}
.circular-progressbar.text p {
	font-size: 1.4rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/map/MapCircularProgessBar/MapCircularProgressBar.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,wBAAwB;CACxB,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,oBAAoB;CACpB,aAAa;AACd;AACA;CACC,kBAAkB;CAClB,uBAAuB;CACvB,wBAAwB;CACxB,sCAAsC;CACtC,aAAa;AACd;AACA;CACC,kBAAkB;CAClB,uBAAuB;CACvB,wBAAwB;CACxB,sCAAsC;CACtC,aAAa;AACd;AACA;CACC,kBAAkB;CAClB,uBAAuB;CACvB,yBAAyB;CACzB,sCAAsC;CACtC,aAAa;AACd;AACA;CACC,kBAAkB;AACnB;AACA;CACC,iBAAiB;AAClB","sourcesContent":[".circular-progressbar {\n\tposition: relative;\n\tborder: black !important;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tpointer-events: none;\n\tz-index: 2000;\n}\n.circular-progressbar.wrapper.single {\n\tposition: absolute;\n\ttop: calc(50vh - 100px);\n\tleft: calc(50vw - 100px);\n\t/* transform: translate(-50%, -50%); */\n\tz-index: 5000;\n}\n.circular-progressbar.wrapper.left {\n\tposition: absolute;\n\ttop: calc(50vh - 100px);\n\tleft: calc(25vw - 100px);\n\t/* transform: translate(-50%, -50%); */\n\tz-index: 5000;\n}\n.circular-progressbar.wrapper.right {\n\tposition: absolute;\n\ttop: calc(50vh - 100px);\n\tright: calc(25vw - 100px);\n\t/* transform: translate(-50%, -50%); */\n\tz-index: 5000;\n}\n.circular-progressbar.text {\n\ttext-align: center;\n}\n.circular-progressbar.text p {\n\tfont-size: 1.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
