// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration-page-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	font-size: 1.6rem;
}

.registration-page-wrapper input {
	width: 300px;
	height: 40px;
	padding: 10px;
	margin-bottom: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 1.2rem;
}
.registration-page-wrapper input:focus {
	outline: none;
	border-color: var(--primary-color2);
}
.register-submit-button {
	width: 300px;
	height: 40px;
	border: none;
	border-radius: 5px;
	background-color: var(--primary-color1);
	color: white;
	font-size: 1.4rem;
	cursor: pointer;
	text-align: center;
	padding: 1rem;
}

.register-submit-button:hover {
	background-color: #0056b3;
}

.response-details {
	font-size: 1.2rem;
	margin: 1rem;
}
.logo-div {
	position: absolute;
	left: 0;
	top: 0;
}
.message-row {
	position: relative;
}
.message-row .message {
	position: absolute;
	bottom: 0px;
	color: red;
	font-size: 1.2rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/LoginRegister/RegistrationPage/RegistrationPage.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,mBAAmB;CACnB,aAAa;CACb,iBAAiB;AAClB;;AAEA;CACC,YAAY;CACZ,YAAY;CACZ,aAAa;CACb,mBAAmB;CACnB,sBAAsB;CACtB,kBAAkB;CAClB,iBAAiB;AAClB;AACA;CACC,aAAa;CACb,mCAAmC;AACpC;AACA;CACC,YAAY;CACZ,YAAY;CACZ,YAAY;CACZ,kBAAkB;CAClB,uCAAuC;CACvC,YAAY;CACZ,iBAAiB;CACjB,eAAe;CACf,kBAAkB;CAClB,aAAa;AACd;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,iBAAiB;CACjB,YAAY;AACb;AACA;CACC,kBAAkB;CAClB,OAAO;CACP,MAAM;AACP;AACA;CACC,kBAAkB;AACnB;AACA;CACC,kBAAkB;CAClB,WAAW;CACX,UAAU;CACV,iBAAiB;AAClB","sourcesContent":[".registration-page-wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 100vh;\n\tfont-size: 1.6rem;\n}\n\n.registration-page-wrapper input {\n\twidth: 300px;\n\theight: 40px;\n\tpadding: 10px;\n\tmargin-bottom: 20px;\n\tborder: 1px solid #ccc;\n\tborder-radius: 5px;\n\tfont-size: 1.2rem;\n}\n.registration-page-wrapper input:focus {\n\toutline: none;\n\tborder-color: var(--primary-color2);\n}\n.register-submit-button {\n\twidth: 300px;\n\theight: 40px;\n\tborder: none;\n\tborder-radius: 5px;\n\tbackground-color: var(--primary-color1);\n\tcolor: white;\n\tfont-size: 1.4rem;\n\tcursor: pointer;\n\ttext-align: center;\n\tpadding: 1rem;\n}\n\n.register-submit-button:hover {\n\tbackground-color: #0056b3;\n}\n\n.response-details {\n\tfont-size: 1.2rem;\n\tmargin: 1rem;\n}\n.logo-div {\n\tposition: absolute;\n\tleft: 0;\n\ttop: 0;\n}\n.message-row {\n\tposition: relative;\n}\n.message-row .message {\n\tposition: absolute;\n\tbottom: 0px;\n\tcolor: red;\n\tfont-size: 1.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
