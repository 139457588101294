// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overflow-indicator {
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-content: center;
	max-height: 25px;
	width: 100%;
	left: 0;
	bottom: 0;
	z-index: 11;
}

.arrow-1 img {
	max-height: 20px;
	object-fit: contain;
	/* padding: 0.3rem; */
	margin: 0.3rem;
}

.nomore img {
	transform: scale(-1);
}

@media (min-width: 500px) {
	.overflow-indicator {
		width: 275px;
	}

	.arrow-1 img {
		max-height: 15px;
		object-fit: contain;
		/* padding:0.5rem 0.5rem; */
		margin: 0.1rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/LeftPanel/GeneralStyles/OverflowIndicator.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,aAAa;CACb,8BAA8B;CAC9B,qBAAqB;CACrB,gBAAgB;CAChB,WAAW;CACX,OAAO;CACP,SAAS;CACT,WAAW;AACZ;;AAEA;CACC,gBAAgB;CAChB,mBAAmB;CACnB,qBAAqB;CACrB,cAAc;AACf;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC;EACC,YAAY;CACb;;CAEA;EACC,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;EAC3B,cAAc;CACf;AACD","sourcesContent":[".overflow-indicator {\n\tposition: absolute;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-content: center;\n\tmax-height: 25px;\n\twidth: 100%;\n\tleft: 0;\n\tbottom: 0;\n\tz-index: 11;\n}\n\n.arrow-1 img {\n\tmax-height: 20px;\n\tobject-fit: contain;\n\t/* padding: 0.3rem; */\n\tmargin: 0.3rem;\n}\n\n.nomore img {\n\ttransform: scale(-1);\n}\n\n@media (min-width: 500px) {\n\t.overflow-indicator {\n\t\twidth: 275px;\n\t}\n\n\t.arrow-1 img {\n\t\tmax-height: 15px;\n\t\tobject-fit: contain;\n\t\t/* padding:0.5rem 0.5rem; */\n\t\tmargin: 0.1rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
