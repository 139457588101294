// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
	justify-self: flex-end;
	background-color: var(--neutral-color2);
	text-align: center;
}

.footer {
	color: var(--primary-color1);
	font-size: 1.4rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.footer p {
	padding: 5px;
	margin-bottom: 0px;
}
.footer p.smaller {
	padding: 0 5px 5px 5px;
	margin-bottom: 0px;
	font-size: 1.2rem;
}

@media (max-width: 700px) {
	.footer {
		flex-direction: column-reverse;
		justify-content: center;
		padding-left: 0;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,uCAAuC;CACvC,kBAAkB;AACnB;;AAEA;CACC,4BAA4B;CAC5B,iBAAiB;CACjB,aAAa;CACb,eAAe;CACf,mBAAmB;CACnB,8BAA8B;AAC/B;;AAEA;CACC,YAAY;CACZ,kBAAkB;AACnB;AACA;CACC,sBAAsB;CACtB,kBAAkB;CAClB,iBAAiB;AAClB;;AAEA;CACC;EACC,8BAA8B;EAC9B,uBAAuB;EACvB,eAAe;CAChB;AACD","sourcesContent":[".footer-container {\n\tjustify-self: flex-end;\n\tbackground-color: var(--neutral-color2);\n\ttext-align: center;\n}\n\n.footer {\n\tcolor: var(--primary-color1);\n\tfont-size: 1.4rem;\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\talign-items: center;\n\tjustify-content: space-between;\n}\n\n.footer p {\n\tpadding: 5px;\n\tmargin-bottom: 0px;\n}\n.footer p.smaller {\n\tpadding: 0 5px 5px 5px;\n\tmargin-bottom: 0px;\n\tfont-size: 1.2rem;\n}\n\n@media (max-width: 700px) {\n\t.footer {\n\t\tflex-direction: column-reverse;\n\t\tjustify-content: center;\n\t\tpadding-left: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
