// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-menu-wrapper {
	position: relative;
	justify-self: center;
	align-self: center;
	background-color: transparent;
	padding: 0.5rem 1.5rem;
	/* background: white; */
	font-family: "Inter-Bold", sens-serif;
	font-weight: 700;
	font-size: 1.4rem;
	text-decoration: none;
	color: var(--primary-color1);
	text-align: center;
}

.hover-menu {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 0rem;
	left: 11rem;
	background-color: #ffffffa5;
	border-radius: 2rem;
	align-items: center;
}
@media (min-width: 500px) {
	.hover-menu-wrapper {
		position: relative;
		align-self: center;
		background-color: transparent;
	}
	.hover-menu {
		position: absolute;
		display: flex;
		flex-direction: column;
		top: 2.7rem;
		left: 0rem;
		background-color: transparent;
		border-radius: 2rem;
		align-items: unset;
	}
}
/* 
 .hover-menu a {
	padding: 0.5rem 1.5rem;
	background: white;
	font-family: Inter-Bold, sens-serif;
	font-weight: 700;
	font-size: 1.4rem;
	text-decoration: none;
	color: var(--primary-color1);
	text-align: center;
}  */
`, "",{"version":3,"sources":["webpack://./src/components/HoverMenuMethods/hoverMenuMethods.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,oBAAoB;CACpB,kBAAkB;CAClB,6BAA6B;CAC7B,sBAAsB;CACtB,uBAAuB;CACvB,qCAAqC;CACrC,gBAAgB;CAChB,iBAAiB;CACjB,qBAAqB;CACrB,4BAA4B;CAC5B,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,aAAa;CACb,sBAAsB;CACtB,SAAS;CACT,WAAW;CACX,2BAA2B;CAC3B,mBAAmB;CACnB,mBAAmB;AACpB;AACA;CACC;EACC,kBAAkB;EAClB,kBAAkB;EAClB,6BAA6B;CAC9B;CACA;EACC,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,UAAU;EACV,6BAA6B;EAC7B,mBAAmB;EACnB,kBAAkB;CACnB;AACD;AACA;;;;;;;;;;IAUI","sourcesContent":[".hover-menu-wrapper {\n\tposition: relative;\n\tjustify-self: center;\n\talign-self: center;\n\tbackground-color: transparent;\n\tpadding: 0.5rem 1.5rem;\n\t/* background: white; */\n\tfont-family: \"Inter-Bold\", sens-serif;\n\tfont-weight: 700;\n\tfont-size: 1.4rem;\n\ttext-decoration: none;\n\tcolor: var(--primary-color1);\n\ttext-align: center;\n}\n\n.hover-menu {\n\tposition: absolute;\n\tdisplay: flex;\n\tflex-direction: column;\n\ttop: 0rem;\n\tleft: 11rem;\n\tbackground-color: #ffffffa5;\n\tborder-radius: 2rem;\n\talign-items: center;\n}\n@media (min-width: 500px) {\n\t.hover-menu-wrapper {\n\t\tposition: relative;\n\t\talign-self: center;\n\t\tbackground-color: transparent;\n\t}\n\t.hover-menu {\n\t\tposition: absolute;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\ttop: 2.7rem;\n\t\tleft: 0rem;\n\t\tbackground-color: transparent;\n\t\tborder-radius: 2rem;\n\t\talign-items: unset;\n\t}\n}\n/* \n .hover-menu a {\n\tpadding: 0.5rem 1.5rem;\n\tbackground: white;\n\tfont-family: Inter-Bold, sens-serif;\n\tfont-weight: 700;\n\tfont-size: 1.4rem;\n\ttext-decoration: none;\n\tcolor: var(--primary-color1);\n\ttext-align: center;\n}  */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
