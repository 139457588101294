// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lan-wrapper {
  position: relative;
  max-height: 3rem;
  margin: 0.5rem;
}
.lan-button {
  padding: 0.5rem;
  background: white;
  font-size: 1.0rem;
  text-decoration: none;
  color: var(--primary-color1);
  text-align: center;
  border: 2px solid var(--primary-color1);
  border-radius: 30rem;
  pointer-events: all;
  cursor: pointer;
}
.lan-button.disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: gray;
  border: 2px solid gray;
  pointer-events: none;
  cursor: default;
  width: 32px;
  height: 32px;
}
.lan-select-box {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 2rem;
  left: 1rem;
  background-color: var(--primary-color2);
  z-index: 3002;
  color: var(--primary-color1);
  width: 3rem;
  /* max-width: 4rem; */
  text-align: center;
  font-weight: bold;
  animation: scroll-from-top 0.4s;
}
.lan-select-box select {
width: 3rem;
}
.lan-select-box-item p {
  margin: 0.2rem;
}

.lan-select-box-item:hover {
  background-color: var(--secondary-color1);
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/LanguageChanger/LanguageChanger.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,qBAAqB;EACrB,4BAA4B;EAC5B,kBAAkB;EAClB,uCAAuC;EACvC,oBAAoB;EACpB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;EACrB,WAAW;EACX,sBAAsB;EACtB,oBAAoB;EACpB,eAAe;EACf,WAAW;EACX,YAAY;AACd;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV,uCAAuC;EACvC,aAAa;EACb,4BAA4B;EAC5B,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,iBAAiB;EACjB,+BAA+B;AACjC;AACA;AACA,WAAW;AACX;AACA;EACE,cAAc;AAChB;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":[".lan-wrapper {\n  position: relative;\n  max-height: 3rem;\n  margin: 0.5rem;\n}\n.lan-button {\n  padding: 0.5rem;\n  background: white;\n  font-size: 1.0rem;\n  text-decoration: none;\n  color: var(--primary-color1);\n  text-align: center;\n  border: 2px solid var(--primary-color1);\n  border-radius: 30rem;\n  pointer-events: all;\n  cursor: pointer;\n}\n.lan-button.disabled {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  align-content: center;\n  color: gray;\n  border: 2px solid gray;\n  pointer-events: none;\n  cursor: default;\n  width: 32px;\n  height: 32px;\n}\n.lan-select-box {\n  cursor: pointer;\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  top: 2rem;\n  left: 1rem;\n  background-color: var(--primary-color2);\n  z-index: 3002;\n  color: var(--primary-color1);\n  width: 3rem;\n  /* max-width: 4rem; */\n  text-align: center;\n  font-weight: bold;\n  animation: scroll-from-top 0.4s;\n}\n.lan-select-box select {\nwidth: 3rem;\n}\n.lan-select-box-item p {\n  margin: 0.2rem;\n}\n\n.lan-select-box-item:hover {\n  background-color: var(--secondary-color1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
