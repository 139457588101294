// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip {
	background-color: white;
	border: 1px solid var(--primary-color1);
}
.custom-tooltip p {
	font-size: 1rem;
	margin: 0.2rem 0.5rem;
}
.custom-tooltip h3 {
	font-size: 1rem;
	margin: 0.2rem 0.5rem;
	text-align: center;
}
@media (min-width: 500px) {
	.custom-tooltip {
		background-color: white;
		border: 1px solid var(--primary-color1);
	}
	.custom-tooltip p {
		font-size: 1.2rem;
	}
	.custom-tooltip h3 {
		font-size: 1.4rem;
		margin: 0.2rem 0.5rem;
		text-align: center;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/charts/chartComponents/CustomTooltip/CustomTooltip.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,uCAAuC;AACxC;AACA;CACC,eAAe;CACf,qBAAqB;AACtB;AACA;CACC,eAAe;CACf,qBAAqB;CACrB,kBAAkB;AACnB;AACA;CACC;EACC,uBAAuB;EACvB,uCAAuC;CACxC;CACA;EACC,iBAAiB;CAClB;CACA;EACC,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;CACnB;AACD","sourcesContent":[".custom-tooltip {\n\tbackground-color: white;\n\tborder: 1px solid var(--primary-color1);\n}\n.custom-tooltip p {\n\tfont-size: 1rem;\n\tmargin: 0.2rem 0.5rem;\n}\n.custom-tooltip h3 {\n\tfont-size: 1rem;\n\tmargin: 0.2rem 0.5rem;\n\ttext-align: center;\n}\n@media (min-width: 500px) {\n\t.custom-tooltip {\n\t\tbackground-color: white;\n\t\tborder: 1px solid var(--primary-color1);\n\t}\n\t.custom-tooltip p {\n\t\tfont-size: 1.2rem;\n\t}\n\t.custom-tooltip h3 {\n\t\tfont-size: 1.4rem;\n\t\tmargin: 0.2rem 0.5rem;\n\t\ttext-align: center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
