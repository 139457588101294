// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container-wrapper1.place-holder {
	min-width: 100%;
	min-height: 75%;
}
.risk-indicator-container-wrapper1.place-holder {
	min-height: 154px;
}
.mosquito-container-wrap1.place-holder {
	min-height: 96px;
}
.clim-container1.place1-holder {
	min-height: 210px;
}
.news-container1.place-holder {
	min-height: 250px;
}
@media (min-width: 500px) {
	.map-container-wrapper1.place-holder {
		min-height: unset;
		height: 75%;
	}
	.risk-indicator-container1-wrap.place-holder {
		min-height: unset;

		height: 154px;
	}
	.mosquito-container-wrap1.place-holder {
		min-height: unset;

		height: 113px;
	}
	.clim-container1.place-holder {
		min-height: unset;

		height: 170px;
	}
	.news-container1.place-holder {
		min-height: unset;

		height: 242px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/LeftPanel/GeneralStyles/FloatiesHeader.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,eAAe;AAChB;AACA;CACC,iBAAiB;AAClB;AACA;CACC,gBAAgB;AACjB;AACA;CACC,iBAAiB;AAClB;AACA;CACC,iBAAiB;AAClB;AACA;CACC;EACC,iBAAiB;EACjB,WAAW;CACZ;CACA;EACC,iBAAiB;;EAEjB,aAAa;CACd;CACA;EACC,iBAAiB;;EAEjB,aAAa;CACd;CACA;EACC,iBAAiB;;EAEjB,aAAa;CACd;CACA;EACC,iBAAiB;;EAEjB,aAAa;CACd;AACD","sourcesContent":[".map-container-wrapper1.place-holder {\n\tmin-width: 100%;\n\tmin-height: 75%;\n}\n.risk-indicator-container-wrapper1.place-holder {\n\tmin-height: 154px;\n}\n.mosquito-container-wrap1.place-holder {\n\tmin-height: 96px;\n}\n.clim-container1.place1-holder {\n\tmin-height: 210px;\n}\n.news-container1.place-holder {\n\tmin-height: 250px;\n}\n@media (min-width: 500px) {\n\t.map-container-wrapper1.place-holder {\n\t\tmin-height: unset;\n\t\theight: 75%;\n\t}\n\t.risk-indicator-container1-wrap.place-holder {\n\t\tmin-height: unset;\n\n\t\theight: 154px;\n\t}\n\t.mosquito-container-wrap1.place-holder {\n\t\tmin-height: unset;\n\n\t\theight: 113px;\n\t}\n\t.clim-container1.place-holder {\n\t\tmin-height: unset;\n\n\t\theight: 170px;\n\t}\n\t.news-container1.place-holder {\n\t\tmin-height: unset;\n\n\t\theight: 242px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
