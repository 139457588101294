// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
}

a {
	-webkit-text-decoration: auto;
	        text-decoration: auto;
}
.navbar {
	display: none;
}
.navbar.mobile {
	display: contents;
}
@media (min-width: 500px) {
	.page-container {
		height: 100%;
		width: 100%;
	}
	.modal-parent {
		position: relative;
		z-index: 5000;
	}
	.navbar {
		display: contents;
	}
	.navbar.mobile {
		display: none;
	}
}
`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,YAAY;CACZ,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC,6BAAqB;SAArB,qBAAqB;AACtB;AACA;CACC,aAAa;AACd;AACA;CACC,iBAAiB;AAClB;AACA;CACC;EACC,YAAY;EACZ,WAAW;CACZ;CACA;EACC,kBAAkB;EAClB,aAAa;CACd;CACA;EACC,iBAAiB;CAClB;CACA;EACC,aAAa;CACd;AACD","sourcesContent":[".app {\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: 100vw;\n\theight: 100vh;\n\toverflow-x: hidden;\n}\n\na {\n\ttext-decoration: auto;\n}\n.navbar {\n\tdisplay: none;\n}\n.navbar.mobile {\n\tdisplay: contents;\n}\n@media (min-width: 500px) {\n\t.page-container {\n\t\theight: 100%;\n\t\twidth: 100%;\n\t}\n\t.modal-parent {\n\t\tposition: relative;\n\t\tz-index: 5000;\n\t}\n\t.navbar {\n\t\tdisplay: contents;\n\t}\n\t.navbar.mobile {\n\t\tdisplay: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
