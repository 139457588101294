// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-logo-wrapper {
	position: absolute;
	top: 5px;
	right: 0px;
	background-color: transparent;
	color: white;
	z-index: 5003;
	-webkit-user-select: none;
	        user-select: none;
}
.image-container {
	display: flex;
}
.image-container img {
	object-fit: contain;
	width: 50px;
	margin: 0rem;
}

@media (min-width: 500px) {
	.image-container img {
		width: 70px;
	}
}
@media (min-width: 500px) and (max-width: 530px) {
	.image-container img {
		filter: drop-shadow(0px 0px 3px rgba(241, 241, 240, 0.582));
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/MapLogo/mapLogo.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,QAAQ;CACR,UAAU;CACV,6BAA6B;CAC7B,YAAY;CACZ,aAAa;CACb,yBAAiB;SAAjB,iBAAiB;AAClB;AACA;CACC,aAAa;AACd;AACA;CACC,mBAAmB;CACnB,WAAW;CACX,YAAY;AACb;;AAEA;CACC;EACC,WAAW;CACZ;AACD;AACA;CACC;EACC,2DAA2D;CAC5D;AACD","sourcesContent":[".map-logo-wrapper {\n\tposition: absolute;\n\ttop: 5px;\n\tright: 0px;\n\tbackground-color: transparent;\n\tcolor: white;\n\tz-index: 5003;\n\tuser-select: none;\n}\n.image-container {\n\tdisplay: flex;\n}\n.image-container img {\n\tobject-fit: contain;\n\twidth: 50px;\n\tmargin: 0rem;\n}\n\n@media (min-width: 500px) {\n\t.image-container img {\n\t\twidth: 70px;\n\t}\n}\n@media (min-width: 500px) and (max-width: 530px) {\n\t.image-container img {\n\t\tfilter: drop-shadow(0px 0px 3px rgba(241, 241, 240, 0.582));\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
