// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-loading-skeleton {
	width: 100%;
	height: 100%; /* Adjust the height to match your chart's size */
	background-color: #f0f0f0; /* Background color for the loading skeleton */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 10px;
	/* border-radius: 4px; */
	overflow: hidden;
}
.chart-loading-skeleton.border {
	width: 90%;
	height: 100%; /* Adjust the height to match your chart's size */
	background-color: #f0f0f0; /* Background color for the loading skeleton */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 10px;
	/* border-radius: 4px; */
	overflow: hidden;
	border-bottom: 1px solid black;
	border-left: 1px solid black;
	margin-left: 1rem;
}

.loading-line {
	width: 100%;
	height: 20px;
	background-color: #e0e0e0;
	margin-bottom: 10px;
	position: relative;
	overflow: hidden;
}
.gradient-loading-line {
	width: 100%;
	height: 100%;
	background: linear-gradient(to right, #e0e0e0, #ffffff, #e0e0e0);
	position: absolute;
	animation: loading-animation 1.5s infinite;
	z-index: 2000;
}

@keyframes loading-animation {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
		opacity: 1;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/skeleton/Skeleton.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY,EAAE,iDAAiD;CAC/D,yBAAyB,EAAE,8CAA8C;CACzE,aAAa;CACb,sBAAsB;CACtB,8BAA8B;CAC9B,aAAa;CACb,wBAAwB;CACxB,gBAAgB;AACjB;AACA;CACC,UAAU;CACV,YAAY,EAAE,iDAAiD;CAC/D,yBAAyB,EAAE,8CAA8C;CACzE,aAAa;CACb,sBAAsB;CACtB,8BAA8B;CAC9B,aAAa;CACb,wBAAwB;CACxB,gBAAgB;CAChB,8BAA8B;CAC9B,4BAA4B;CAC5B,iBAAiB;AAClB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,yBAAyB;CACzB,mBAAmB;CACnB,kBAAkB;CAClB,gBAAgB;AACjB;AACA;CACC,WAAW;CACX,YAAY;CACZ,gEAAgE;CAChE,kBAAkB;CAClB,0CAA0C;CAC1C,aAAa;AACd;;AAEA;CACC;EACC,4BAA4B;CAC7B;;CAEA;EACC,2BAA2B;EAC3B,UAAU;CACX;AACD","sourcesContent":[".chart-loading-skeleton {\n\twidth: 100%;\n\theight: 100%; /* Adjust the height to match your chart's size */\n\tbackground-color: #f0f0f0; /* Background color for the loading skeleton */\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-between;\n\tpadding: 10px;\n\t/* border-radius: 4px; */\n\toverflow: hidden;\n}\n.chart-loading-skeleton.border {\n\twidth: 90%;\n\theight: 100%; /* Adjust the height to match your chart's size */\n\tbackground-color: #f0f0f0; /* Background color for the loading skeleton */\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-between;\n\tpadding: 10px;\n\t/* border-radius: 4px; */\n\toverflow: hidden;\n\tborder-bottom: 1px solid black;\n\tborder-left: 1px solid black;\n\tmargin-left: 1rem;\n}\n\n.loading-line {\n\twidth: 100%;\n\theight: 20px;\n\tbackground-color: #e0e0e0;\n\tmargin-bottom: 10px;\n\tposition: relative;\n\toverflow: hidden;\n}\n.gradient-loading-line {\n\twidth: 100%;\n\theight: 100%;\n\tbackground: linear-gradient(to right, #e0e0e0, #ffffff, #e0e0e0);\n\tposition: absolute;\n\tanimation: loading-animation 1.5s infinite;\n\tz-index: 2000;\n}\n\n@keyframes loading-animation {\n\t0% {\n\t\ttransform: translateX(-100%);\n\t}\n\n\t100% {\n\t\ttransform: translateX(100%);\n\t\topacity: 1;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
