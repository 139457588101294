// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-element {
  position: relative;
  padding: 0.1rem;
}

.tooltip {
  position: absolute;
  background-color: var(--primary-color1);
  color: white;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 1s;
  top: -2rem;
  right: 2rem;
  width: max-content;
  z-index: 500;
  box-shadow: 0px 0px 6px 0px black;
}

.tooltip-element:hover .tooltip {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tooltip/ToolTip.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,uCAAuC;EACvC,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,sBAAsB;EACtB,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".tooltip-element {\n  position: relative;\n  padding: 0.1rem;\n}\n\n.tooltip {\n  position: absolute;\n  background-color: var(--primary-color1);\n  color: white;\n  padding: 5px;\n  border-radius: 5px;\n  opacity: 0;\n  transition: opacity 1s;\n  top: -2rem;\n  right: 2rem;\n  width: max-content;\n  z-index: 500;\n  box-shadow: 0px 0px 6px 0px black;\n}\n\n.tooltip-element:hover .tooltip {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
